import { useLang } from "hooks/useLang"
import { useRouter } from "next/router"
import Button from "./Form/Button"
import CustomImage from "./atoms/Image/CustomImage"

interface DiscoverCardProps {
    title: string
    image: string
}

const DiscoverCard = ({ title, image }: DiscoverCardProps) => {
    const { t } = useLang()
    const { push } = useRouter()

    return (
        <div
            onClick={() => push(`/events/discover/${title}`)}
            className="group relative flex h-[165px] w-full items-center justify-center overflow-hidden rounded-3xl  transition-all hover:shadow-md lg:h-[315px]">
            <CustomImage
                src={image}
                alt={title}
                fill
                priority
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                style={{ objectFit: "cover" }}
                className="transition-all md:grayscale-[0.4] md:group-hover:grayscale-[0]"
            />
            <div className="z-10 flex h-full w-full flex-col justify-end bg-gradient-to-t from-black from-0% to-40% p-5 md:flex-row md:items-end md:justify-between">
                <p className="text-xl font-bold capitalize text-white lg:text-3xl">
                    {t(title)}
                </p>

                <Button
                    text={t("discover")}
                    onClick={() => push(`/events/discover/${title}`)}
                    className="hidden px-4 md:flex md:px-6 md:py-1"
                />
            </div>
        </div>
    )
}

export default DiscoverCard
