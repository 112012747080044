import React from "react"
import { useLang } from "hooks/useLang"

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    value: string | number
    label: string
    options: {
        id: string | number
        label: string
        value: string
        disabled?: boolean
    }[]
    selectedFields?: string[]
}

const MySelectInput = ({
    label,
    onChange,
    value,
    options,
    selectedFields,
    ...props
}: Props) => {
    const { t } = useLang()
    return (
        <div className="relative">
            <select
                {...props}
                onChange={onChange}
                defaultValue={props.defaultValue}
                className=" bg-transparent font-sans text-sm font-normal text-[16px]  underline bg-transparent text-[16px] text-orange-500 ml-1 appearance-none"
            >
                {options.map((option) => (
                    <option
                        label={t(option.label)}
                        value={option.value}
                        key={option.id}
                        className="capitalize text-[16px]"
                        disabled={option.disabled}
                    />
                ))}
            </select>
        </div>
    )
}

export default MySelectInput
