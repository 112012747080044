export const cities = [
    {
        id: 0,
        value: "Your location",
        label: "Your_location",
        disabled: true,
    },
    {
        id: 1,
        value: "all",
        label: "all_cities",
    },
    {
        id: 2,
        value: "jeddah",
        label: "Jeddah",
    },
    {
        id: 3,
        value: "riyadh",
        label: "Riyadh",
    },
    {
        id: 4,
        value: "medina",
        label: "Medina",
    },
]
